import React, { useEffect, useMemo, useState } from 'react';
import { PACKSHOTS_DEFAULT } from '../config';
import countryList from '../lib/countries';
import Select from './Select';
import { removeDuplicateObjects } from '../utils/editions';

const h3Title = 'tw-text-red tw-mb-[15px] tw-font-normal';

const buttonConfig = {
  default: {
    color: 'tw-transparent tw-text-tundora hover:tw-bg-highlight',
  },
  active: {
    color: 'tw-bg-tundora tw-text-white hover:tw-bg-tundora',
  },
};

const NO_COUNTRY_SELECTED_VALUE = "NONE"

const GameContent = ({
  locale,
  etailersResource,
  translationResource,
  title,
  showPackshots,
}) => {
  const [etailersData, setEtailers] = useState(etailersResource);
  const etailers = etailersData.read();

  if (etailers['ROW']) {
    Object.keys(etailers).forEach(countryCode => {
      if (countryCode !== 'ROW') {
        etailers[countryCode] = etailers[countryCode].concat(etailers['ROW'].filter(
          item2 => !etailers[countryCode].some(item1 => item1.link === item2.link)));
      }
    });
  }

  const [translationData, setTranslationData] = useState(translationResource);
  const translation = translationData.read();
  const [platforms, setPlatforms] = useState([]);
  const [editions, setEditions] = useState([]);
  const [stores, setStores] = useState([]);
  const [currentPlatform, setCurrentPlatform] = useState();
  const [currentEdition, setCurrentEdition] = useState({
    name: '',
    packshot: '',
  });
  const [currentStore, setCurrentStore] = useState();
  const [error, setError] = useState('');
  const NO_SELECTED_COUNTRY = {
    value: NO_COUNTRY_SELECTED_VALUE,
    label: translation.country.label
  }

  const countries = useMemo(
    () => {
      if (Object.keys(etailers).includes("ROW")) {
        return (
          countryList.map(country => ({
            value: country.id,
            label: country.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
        )
      } else {
        return (
          Object.keys(etailers)
            .map((key) => ({
              value: key,
              label: countryList.find((el) => el.id === key).name,
            }))
          .sort((a, b) => a.label.localeCompare(b.label))
        )
      }
    }, [etailers],
  );
  const [countryValue, setCountryValue] = useState();
  const productTitle = useMemo(
    () =>
      title ||
      (etailers && countryValue
        ? etailers[countryValue.value][0].meta.custom.title
        : ''),
    [etailers, title, countryValue],
  );
  useEffect(() => {
    setTranslationData(translationResource);
    setEtailers(etailersResource);
  }, [etailersResource, translationResource]);

  useEffect(() => {
    if (countries)
      setCountryValue(
        countries.find((c) => locale.indexOf(c.value) !== -1) || NO_SELECTED_COUNTRY,
      );
  }, [countries, locale]);

  useEffect(() => {
    if (etailers && countryValue) {
      var selectedCountry = countryValue.value || "ROW";
      var selectedEtailer = etailers[selectedCountry] || etailers["ROW"];
      if (!selectedEtailer) return
      const platformsData = selectedEtailer
        .map((el) => el.meta.platform)
        .sort()
        .filter((el, i, array) => {
          return array.indexOf(el) === i;
        });
      setPlatforms(platformsData);
      setCurrentPlatform(
        platformsData.includes(currentPlatform)
          ? currentPlatform
          : platformsData[0],
      );
      const editionsData = removeDuplicateObjects(
        selectedEtailer
          .filter(
            (el) => el.meta.platform === (currentPlatform || platformsData[0]),
          )
          .map((el) => ({ name: el.meta.edition, packshot: el.meta.packshot }))
          .sort(),
        'name',
      );

      setEditions(editionsData);

      setCurrentEdition(
        editionsData
          .map((d) => d && d.name)
          .includes(currentEdition && currentEdition.name)
          ? currentEdition
          : editionsData[0],
      );

      const storesWithPlatformEdition = selectedEtailer.filter(
        (el) =>
          el.meta.platform === (currentPlatform || platformsData[0]) &&
          el.meta.edition ===
            ((currentEdition && currentEdition.name) ||
              (editionsData[0] && editionsData[0].name)),
      );

      const storesData = storesWithPlatformEdition
        .map((el) => el.meta.store)
        .filter((el, i, array) => array.indexOf(el) === i)
        .map((el) => ({
          link: storesWithPlatformEdition.find((e) => e.meta.store === el).link,
          logo: storesWithPlatformEdition.find((e) => e.meta.store === el).logo,
          name: el,
        }));
      setStores(storesData);
      setCurrentStore(
        storesData.find((el) => currentStore && currentStore.name === el.name)
          ? currentStore
          : storesData[0],
      );
    }
  }, [countryValue, etailers, currentEdition, currentPlatform, currentStore]);

  return (
    <div className="text-sm">
      {countryValue && (
        <>
          <h1 className="tw-text-xl tw-max-w-[259px] md:tw-max-w-[454px] tw-font-bold tw-mb-[30px]">
            {productTitle}
          </h1>
          <div className="tw-relative tw-pb-[22px] tw-border-0 tw-border-b tw-border-solid tw-border-doveGray tw-text-sm">
            <h3 className={h3Title}>{translation.country.label}</h3>
            {/* {console.log('etailers:', etailers, 'countryVal:', countryValue)} */}
            <Select
              options={[NO_SELECTED_COUNTRY, ...countries]}
              onChange={(val) => setCountryValue(val)}
              defaultValue={countryValue}
            />
          </div>
          {countryValue.value !== NO_COUNTRY_SELECTED_VALUE &&
            <>
              <div className="tw-pt-[22px] tw-border-0 tw-border-b tw-border-solid tw-border-doveGray tw-text-sm">
                <h3 className={h3Title}>{translation.platform.label}</h3>
                <div>
                  {platforms.map((platform) => (
                    <label
                      key={platform}
                      className={`tw-border tw-border-tundora tw-border-solid !tw-rounded-md tw-border-black tw-cursor-pointer tw-text-xs tw-text-center tw-px-[22px] tw-py-[9.5px] tw-inline-block tw-mr-[25px] tw-mb-[20px] hover:tw-bg-highlight tw-w-full md:tw-w-auto ${
                        buttonConfig[
                          currentPlatform === platform ? 'active' : 'default'
                        ].color
                      }`}>
                      {platform}
                      <input
                        name="platform"
                        className="tw-hidden"
                        value={platform}
                        type="radio"
                        checked={platform === currentPlatform}
                        onChange={(e) => {
                          setCurrentPlatform(e.target.value);
                        }}
                      />
                    </label>
                  ))}
                </div>
              </div>
              <div className="tw-pt-[22px] tw-border-0 tw-border-b tw-border-solid tw-border-doveGray tw-text-sm">
                <h3 className={h3Title}>{translation.edition.label}</h3>
                <div className={`tw-flex tw-flex-wrap ${
                    showPackshots && editions.length === 2
                      ? 'tw-justify-around'
                      : showPackshots
                        ? 'tw-justify-between'
                        : ''
                      }`
                    }>
                  {editions.map((edition) => {
                    const hasAPackshot =
                      showPackshots && edition.packshot !== PACKSHOTS_DEFAULT;
                    return (
                      <div
                        key={edition.name}
                        className={`${hasAPackshot ? 'tw-w-[140px]' : 'tw-w-full md:tw-w-auto md:tw-mr-[20px]'}`}>
                        {hasAPackshot && (
                          <img
                            alt={edition.name}
                            className="tw-max-w-[140px] tw-mb-[8px]"
                            src={edition.packshot}
                          />
                        )}
                        <label
                          key={edition.name}
                          className={`tw-border tw-border-tundora tw-border-solid tw-rounded-md tw-cursor-pointer tw-text-xs tw-text-center tw-px-[22px] tw-py-[9.5px] tw-inline-block ${
                            hasAPackshot
                              ? 'tw-w-full tw-max-w-[140px]'
                              : 'tw-w-full md:tw-w-auto'
                          } tw-mb-[20px] ${
                            buttonConfig[
                              currentEdition.name === edition.name
                                ? 'active'
                                : 'default'
                            ].color
                          }`}>
                          {edition.name}
                          <input
                            className="tw-hidden"
                            name="edition"
                            value={edition}
                            checked={edition === currentEdition.name}
                            type="radio"
                            onChange={(e) => setCurrentEdition(edition)}
                          />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="tw-relative tw-grid tw-pt-[22px] tw-grid-cols-[repeat(auto-fill,minmax(136px,max-content))] md:tw-grid-cols-[repeat(auto-fill,minmax(109px,max-content))] tw-gap-x-[25px] tw-gap-y-5">
                {stores.map((el) => (
                  <a
                    className="tw-relative tw-block tw-w-[136px] md:tw-w-[109px] tw-h-[78px] md:tw-h-[61px] tw-overflow-hidden tw-border tw-border-red tw-border-solid tw-rounded-md after:tw-absolute after:tw-block after:tw-w-full after:tw-h-full after:tw-top-0 hover:after:tw-bg-black/20"
                    href={el.link}
                    target="_blank"
                    rel="noreferrer"
                    key={el.link}
                  >
                    <img className="tw-w-full" src={el.logo} alt={el.name} />
                  </a>
                ))}
              </div>
            </>
          }
        </>
      )}
    </div>
  );
};

export default GameContent;
