import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { ETAILERS_API, TRANSLATION_API } from '../config';
import fetchData from '../services/fetchData';
import wrapPromise from '../utils/wrapPromise';
import GameContent from './GameContent';
import { CloseIcon } from './icons';
import useEscapeKey from '../hooks/useEscapeKey';
import useClickOutside from '../hooks/useClickOutside';

const modalConfig = {
  close: 'tw-transition-opacity tw-opacity-0 tw-pointer-events-none',
  open: 'tw-transition-opacity tw-opacity-100 tw-pointer-events-auto',
};

const EtailersModal = ({
  isOpen,
  etailers_id,
  locale,
  title,
  zIndex,
  onClose,
  showPackshots,
}) => {
  const overlayEl = useRef(null);
  const contentEl = useRef(null);
  const [modalOpen, setIsOpen] = useState(isOpen);

  const closeModal = useCallback(() => {
    document.documentElement.style.overflow = 'initial';
    setIsOpen(false);
    onClose(true);
  }, [onClose, setIsOpen]);

  useEscapeKey(closeModal);
  useClickOutside(closeModal, contentEl);

  const etailersResource = useMemo(() => {
    if (etailers_id) {
      return wrapPromise(fetchData(`${ETAILERS_API}/${etailers_id}`));
    } else {
      return null;
    }
  }, [etailers_id]);

  const translationResource = useMemo(
    () =>
      wrapPromise(fetchData(`${TRANSLATION_API}/DP-WDG-ETAILERS/${locale}`)),
    [locale],
  );

  useEffect(() => {
    // console.log('isOpen', isOpen);
    setIsOpen(isOpen);
  }, [isOpen]);

  if (!etailers_id) return null;

  return (
    <div
      ref={overlayEl}
      className={`[&_*]:tw-box-border tw-font-sans tw-fixed tw-top-0 tw-w-full tw-h-full tw-grid tw-justify-center tw-items-center tw-bg-black/75 ${
        modalOpen ? modalConfig.open : modalConfig.close
      }`}
      style={{ zIndex: modalOpen ? zIndex : -1 }}
      aria-expanded={modalOpen ? 'true' : 'false'}>
      <div
        ref={contentEl}
        className="tw-absolute tw-top-[50px] tw-overflow-x-hidden tw-bottom-[50px] tw-left-0 tw-right-0 tw-rounded-md tw-m-auto tw-min-w-[320px] tw-max-w-[362px] md:tw-max-w-[556px] tw-w-full tw-max-h-[589px] tw-overflow-auto tw-bg-white tw-border-r-6 tw-p-[30px] tw-outline-none"
        isOpen={modalOpen}
        aria-label="Modal">
        <div className="tw-relative">
          <div
            dangerouslySetInnerHTML={{
              __html:
                "<style>@import url('https://use.typekit.net/zom3wve.css');</style>",
            }}
          />
          <Suspense fallback={<div>Loading...</div>}>
            <GameContent
              locale={locale}
              title={title}
              etailersResource={etailersResource}
              translationResource={translationResource}
              showPackshots={showPackshots}
            />
          </Suspense>
        </div>
        <button
          aria-label="Close"
          className="tw-absolute tw-top-8 tw-right-8 tw-cursor-pointer tw-bg-transparent tw-border-0"
          onClick={closeModal}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

EtailersModal.propTypes = {
  locale: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  zIndex: PropTypes.number,
  onClose: PropTypes.func,
  showPackshots: PropTypes.bool,
};

EtailersModal.defaultProps = {
  isOpen: false,
  zIndex: 999999999,
  onClose: () => {},
};

export default EtailersModal;
