import './index.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';

import EtailersModal from './components/EtailersModal';

const EtailersComp = (props) => {
  const [configState, setState] = useState(props);
  const { showing } = configState;

  const handleChange = (argLocale) => {
    window.etailersConfig.locale = argLocale;
    setState((prevState, state) => ({
      ...prevState,
      ...state,
      locale: argLocale,
    }));
  };

  const handleRemove = (bool = true) => {
    setState((prevState, state) => ({
      ...prevState,
      ...state,
      showing: !bool,
      isOpen: !bool,
      showPackshots: !bool,
    }));
  };

  const handleConfig = (newValue) => {
    const hasTheme = Object.keys(newValue).includes('theme');
    const configHasTheme = Object.keys(window.etailersConfig).includes('theme');
    const newObj =
      hasTheme && configHasTheme
        ? {
            ...newValue,
            theme: { ...window.etailersConfig.theme, ...newValue.theme },
          }
        : newValue;
    window.etailersConfig = {
      ...window.etailersConfig,
      ...newObj,
    };

    setState((prevState, state) => ({
      ...prevState,
      ...state,
      ...newObj,
    }));
  };

  const handleToggle = (status) => {
    setState((prevState, state) => ({
      ...prevState,
      ...state,
      showing: status,
      isOpen: status,
      showPackshots: status,
    }));
  };

  const handleClose = (val) => {
    setState((prevState, state) => ({
      ...prevState,
      ...state,
      showing: !val,
      isOpen: !val,
      showPackshots: !val,
    }));
  };

  const show = (newState = window.etailersConfig) => {
    setState((prevState, state) => ({
      ...prevState,
      ...state,
      ...newState,
      showing: true,
      clicked: true,
      isOpen: true,
    }));
  };

  useEffect(() => {
    window.etailersConfig = {
      ...configState,
      changeLocale: handleChange,
      remove: handleRemove,
      removed: !showing,
      updateConfig: handleConfig,
      clicked: configState.clicked,
      show,
    };
  }, [configState, showing]);

  return (
    <EtailersModal
      {...configState}
      toggle={handleToggle}
      onClose={handleClose}
    />
  );
};

EtailersComp.propTypes = {
  locale: PropTypes.string,
};

EtailersComp.defaultProps = {
  locale: 'en_GB',
};

const renderComponent = (mountingNode) => {
  const root = ReactDOM.createRoot(mountingNode);
  root.render(<EtailersComp {...window.etailersConfig} />, mountingNode);
};

(() => {
  if (!window.etailersConfig.isRunning) {
    //const body = document.getElementsByTagName('BODY')[0]
    const mountingDiv = document.createElement('div');
    mountingDiv.id = 'etailers';
    document.body.appendChild(mountingDiv);
    window.etailersConfig.isRunning = true;
    return renderComponent(mountingDiv);
  }
  return null;
})();
