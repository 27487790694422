
const fetchData = async (url) => {
	try {
		const res = await fetch(url, {
			method: 'GET'
		});

		const data = await res.json();
        
		return data;
	} catch (error) {
		return error;
	}
};

export default fetchData;
