import React, { useEffect, useRef, useState } from 'react';
import { AngleIcon, SearchIcon } from './icons';

const styles = {};

const Select = ({ options, placeholder, onChange, defaultValue }) => {
  const input = useRef(null);
  const list = useRef(null);
  const select = useRef(null);

  const [opened, setOpened] = useState(false);
  const [countryLabel, setCountryLabel] = useState('');
  const [countryValue, setCountryValue] = useState('');
  const [search, setSearch] = useState('');

  const handleOpen = async () => {
    await setOpened(!opened);
    if (input.current) {
      input.current.focus();
    }
  };

  const handleSelect = (e) => {
    if (e.target.tagName !== 'DIV') return; // not on TD? Then we're not interested
    setCountryLabel(e.target.innerText);
    setCountryValue(e.target.dataset.value);
    setSearch('');
    setOpened(false);
    onChange({ value: e.target.dataset.value, label: e.target.innerText });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    list.current.scrollTop = 0;
  };

  const focus = () => {
    if (input.current) {
      input.current.focus();
    }
  };

  const handleClickOutside = (e) => {
    const isClickInside = select.current.contains(e.target);
    if (!isClickInside) {
      setOpened(false);
      setSearch('');
    }
  };

  useEffect(() => {
    if (countryValue) {
      console.log('countryValue', countryValue);
      const currElement = document.querySelector(
        `[data-value=${countryValue}]`,
      );
      if (currElement) list.current.scrollTop = currElement.offsetTop || 0;
    }
  }, [opened, list, countryValue]);

  useEffect(() => {
    setCountryLabel(defaultValue.label);
    setCountryValue(defaultValue.value);
    onChange(defaultValue);
  }, [defaultValue, onChange]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div
      className="tw-relative tw-h-[38px] tw-w-full tw-max-w-[400px] tw-grid tw-items-center tw-px-[14px] tw-text-sm tw-border tw-border-solid tw-border-tundora tw-rounded-md"
      ref={select}>
      {!search.length && (
        <div
          className="tw-relative tw-flex tw-w-full tw-items-center tw-text-left tw-text-sm tw-text-placeholder"
          onClick={handleOpen}>
          {opened && (
            <SearchIcon className="tw-text-lg tw-text-placeholder tw-mr-2.5" />
          )}
          {countryLabel || placeholder}
        </div>
      )}
      {opened && (
        <input
          onChange={handleSearch}
          className="tw-absolute tw-top-0 tw-w-full tw-bg-transparent tw-px-10 tw-h-[37px] tw-border-0 tw-text-sm tw-text-left"
          type="text"
          value={search}
          ref={input}
          onClick={focus}
        />
      )}
      {opened && (
        <div
          className="tw-absolute tw-top-[38px] tw-w-full tw-max-h-[224px] tw-overflow-scroll tw-bg-white tw-z-10 tw-border tw-border-t-0 tw-border-solid tw-border-tundora"
          onClick={handleSelect}
          ref={list}>
          {options
            .filter(
              (el) =>
                el.label
                  .toLowerCase()
                  .substr(0, search.length)
                  .indexOf(search.toLocaleLowerCase()) !== -1,
            )
            .map((el) => (
              <div
                className="tw-relative tw-w-full tw-text-sm tw-p-3.5 tw-text-left tw-text-black hover:tw-bg-highlight"
                key={el.id || el.label}
                data-value={el.value}
                data-label>
                {el.label}
              </div>
            ))}
        </div>
      )}
      <div className="tw-absolute tw-right-3.5" onClick={handleOpen}>
        {!opened ? <AngleIcon /> : <AngleIcon />}
      </div>
    </div>
  );
};

export default Select;
